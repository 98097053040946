import cn from "classnames";
import { ErrorMessage, Field } from "formik";
import { InputHTMLAttributes, useEffect, useRef } from "react";

import * as styles from "./TextFormControl.module.scss";

interface TextFormControlProps extends InputHTMLAttributes<HTMLInputElement> {
  variantName?: string;
  adoptionClassName?: string;
  name: string;
  label: string;
  isFocusedOnRender?: boolean;
}

const TextFormControl = ({
  variantName,
  adoptionClassName,
  name,
  label,
  isFocusedOnRender,
  ...props
}: TextFormControlProps): JSX.Element => {
  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocusedOnRender) {
      fieldRef.current?.focus();
    }
  }, [isFocusedOnRender]);

  return (
    <div
      className={cn("form-control", styles.formControl, adoptionClassName, {
        [styles[variantName as string]]: variantName,
      })}
    >
      <Field id={name} name={name} innerRef={fieldRef} {...props} />
      <label htmlFor={name}>{label}</label>
      <ErrorMessage
        className={cn(styles.formFieldFeedback, styles.formFieldFeedbackAlert)}
        component="p"
        name={name}
      />
    </div>
  );
};

export default TextFormControl;
