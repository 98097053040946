import { graphql } from "gatsby";

import Name from "../../organisms/lead/Name";
import OnboardingLayout from "../../organisms/lead/OnboardingLayout";

const NamePage = (): JSX.Element => (
  <OnboardingLayout>
    <Name />
  </OnboardingLayout>
);

export default NamePage;

export const query = graphql`
  query NamePageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
